<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Informes de Visitas</h4>
            <div class="small text-muted">Administración de todos los informes de visitas</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Informe">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="3">
            <b-row>
              <b-col md="6" class="pr-0">
                <b-form-input type="date" size="md" v-model="filter.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="6" class="pl-0">
                <b-form-input type="date" size="md" v-model="filter.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="6">
            <b-row>
              <b-col sm="3">
                <v-select :options="arr.filter.staff" v-model="filter.staff" placeholder="Staff" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>        
              <b-col sm="3">
                <v-select :options="arr.filter.sellers" v-model="filter.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>       
              <b-col sm="3">
                <v-select :options="arr.filter.customers" v-model="filter.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>       
              <b-col sm="3">
                <v-select :options="arr.filter.read" v-model="filter.read" placeholder="Leido" :multiple="false" :select-on-tab="true"></v-select>                
              </b-col>               
            </b-row>
          </b-col>    
          <b-col sm="3">
            <b-row>
              <b-col sm="6">
                <b-button variant="outline-dark" @click="filterReport()">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
              <b-col sm="6">
                <b-link>
                  <export-excel
                    class = 'pull-right mt-3'
                    :data = "arr.export"
                    worksheet = "Informe de Visitas"
                    name = "reports-visits.xls">
                    Exportar Datos
                  </export-excel>            
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="formartedItems"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"     
                    v-if="table.items.length || table.isBusy">

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:cell(id)="data">
                  <b>{{ data.item.id }}</b>
                </template>

                <template v-slot:cell(date)="data">
                  {{ moment(data.item.date).format('DD MMMM YYYY') }}
                </template>

                <template v-slot:cell(responsable)="data">
                  <div v-if="data.item.staff_id">
                    <b-avatar :src="data.item.staff.image"
                              v-if="data.item.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>                         
                    <b-icon icon="blank"></b-icon>
                    <b :title="'STAFF: ' + data.item.staff.name">
                      {{data.item.staff.name}}
                    </b>                                          
                  </div>
                  <div v-else>                    
                    <b-avatar :src="data.item.seller.staff.image"
                              v-if="data.item.seller.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>     
                    <b-icon icon="blank"></b-icon>
                    <b :title="'VENDEDOR: ' + data.item.seller.staff.name">
                      {{data.item.seller.staff.name}}
                    </b>  
                  </div>                  
                </template>

                <template v-slot:cell(customers)="data">       
                  <div v-if="data.item.customers_id">                        
                    <b-avatar :src="data.item.customer.image"
                              v-if="data.item.customer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>     
                    &nbsp;<b>{{data.item.customer.name}}</b>   
                    <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />               
                  </div>
                </template>

                <template v-slot:cell(buy)="data">                  
                  <div v-if="data.item.buy">                    
                    <div v-if="data.item.exceed_previous_order" class="text-success">
                      <b>Nos compró mas que el pedido anterior.</b>
                    </div>
                    <div v-else class="text-warning">
                      <b>Nos compró menos que el pedido anterior.</b>
                    </div>
                  </div>
                  <div v-else class="text-danger">
                    <b>No nos compró.</b>
                  </div>

                  <div v-if="data.item.buy_competition" class="text-danger">                    
                    <b>Le compró a la competencia</b>
                  </div>
                  <div v-else class="text-success">
                    <b>No le compró a la competencia</b>
                  </div>           
                </template>

                <template v-slot:cell(read)="data">
                  <b-icon icon="toggle-on"  @click="checkRead(data.item)" class="h4 report-visits-check-read-table" v-if="!data.item.read" v-b-tooltip.hover title="Sin Leer"></b-icon>
                  <b-icon icon="toggle-off" class="h4" v-else v-b-tooltip.hover title="Leido"></b-icon>
                </template>

                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >50" :title="data.item.observations">
                      {{data.item.observations.substr(0, 50)}}
                    </div>
                    <div v-else>
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">

                    <b-dropdown-item @click="irDetail(data.item)">
                      <b-icon icon="card-text"></b-icon> Detalle
                    </b-dropdown-item>       

                    <b-dropdown-item @click="checkRead(data.item)" v-if="!data.item.read">
                      <b-icon icon="eye-fill"></b-icon> Marcar como leido
                    </b-dropdown-item>       
                    <b-dropdown-item @click="checkNotRead(data.item)" v-if="data.item.read">
                      <b-icon icon="eye-slash-fill"></b-icon> Marcar como no leido
                    </b-dropdown-item>                           

                    <b-dropdown-item @click="sendPrinterOrder(data.item)">
                      <b-icon icon="printer"></b-icon> Imprimir
                    </b-dropdown-item>    

                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)" v-if="access.elements.editReport">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deleteReport">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterReport()" />
            </nav>            
          </b-col>          
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              no-close-on-esc
              no-close-on-backdrop               
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="6">
            <b-form-group label="Fecha">
              <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>

          <b-col md="6">
            <FindObject render="search"
                        type="customers" 
                        @select-object="loadCustomers($event)" 
                        :valueID="crud.form.customers_id"/>
          </b-col>          
        </b-row>

        <form-wizard v-if="crud.form.customers_id"
                      title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save">
            
          <tab-content title="Cliente" icon="fa fa-handshake-o">          
            <b-row>
              <b-col md="12">
                <b-card>                    
                  <b-row>
                    <b-col>
                      <b-form-group label="¿Compró?">
                        <b-form-checkbox 
                          v-model="crud.form.buy" 
                          switch>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                                
                  <b-row v-show="crud.form.buy">
                    <b-col>
                      <b-form-group label="¿Supera pedido anterior?">
                        <b-form-checkbox 
                          v-model="crud.form.exceed_previous_order" 
                          switch>
                        </b-form-checkbox>
                      </b-form-group>                            
                    </b-col>
                  </b-row>
                  <b-row v-show="!crud.form.buy || !crud.form.exceed_previous_order">
                    <b-col>
                      <b-form-group label="Motivo">
                        <b-form-checkbox-group
                          v-model="crud.form.reason"
                          :options="arr.options.reason"
                          switches
                          stacked
                        ></b-form-checkbox-group>
                      </b-form-group> 
                    </b-col>
                  </b-row>                 
                </b-card>
              </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Competencia" icon="fa fa-briefcase">    
            <b-row>      
              <b-col md="12">            
                <b-card>                      
                  
                  <b-row>
                    <b-col md="6">
                      <b-row>
                        <b-col>
                          <b-form-group label="¿Compra a la competencia?">
                            <b-form-checkbox 
                              v-model="crud.form.buy_competition" 
                              switch>
                            </b-form-checkbox>
                          </b-form-group>   
                        </b-col>
                      </b-row>                
                      
                      <b-row v-show="crud.form.buy_competition">
                        <b-col>
                          <b-form-group label="¿A quien?">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.form.name_competition"
                                          required
                                          placeholder="Ingresar nombre de la competencia">
                            </b-form-input>           
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-show="crud.form.buy_competition">
                        <b-col>
                          <b-form-group label="¿Que tipo de productos (precio/calidad) le vende la competencia?">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.form.competition_products"
                                          required
                                          placeholder="Ingresar productos que le vende la competencia">
                            </b-form-input> 
                          </b-form-group>
                        </b-col>            
                      </b-row>                      
                    </b-col>

                    <b-col md="6">                                    
                      <b-row v-show="crud.form.buy_competition">      
                        <b-col>      
                          <b-form-group label="Motivos">
                            <b-form-checkbox-group
                              v-model="crud.form.motives"
                              :options="arr.options.competition"
                              switches
                              stacked
                            ></b-form-checkbox-group>
                          </b-form-group> 
                          
                          <b-row v-show="this.crud.form.motives.indexOf('por_financiacion') > -1">
                            <b-col>
                              <b-form-group label="Detallar la financiación aplicada por la competencia">
                                <b-form-input type="text"
                                              size="sm"
                                              v-model="crud.form.financing_detail"
                                              required
                                              placeholder="Detallar de financiación">      
                                </b-form-input>                                          
                              </b-form-group>
                            </b-col>            
                          </b-row>

                          <b-row v-show="this.crud.form.motives.indexOf('por_otros_motivos') > -1">
                            <b-col>
                              <b-form-group label="Detallar los otros motivos">
                                <b-form-input type="text"
                                              size="sm"
                                              v-model="crud.form.other_motives"
                                              required
                                              placeholder="Detallar otros motivos">      
                                </b-form-input>                                                  
                              </b-form-group>
                            </b-col>            
                          </b-row>
                          
                        </b-col>
                      </b-row>  
                    </b-col>
                  </b-row>

                </b-card>            
              </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Observaciones" icon="fa fa-flag-checkered">    
            <b-row>      
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>
          </tab-content>
        </form-wizard>


        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>                  
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import FindObject from '@/components/inc/find/findObject' 
  import Vue from 'vue'
  import excel from 'vue-excel-export'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Storage from '@/handler/storageSession'
  import Printer from '@/components/inc/printer/printer'

  Vue.use(excel)

  export default {
    components: {
      FindObject,
      FormWizard,
      TabContent,
      Printer    
    },      
    data: () => {
      return {      
        access: {
          module_id: Modules.INFORME_VISITAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudReport',
          elements: {
            editReport: true,
            deleteReport: true             
          }
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: "align-middle"},
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'responsable', label: 'Responsable', class: "align-middle"},
            {key: 'customers', label: 'Cliente', class: "align-middle"},
            {key: 'buy', label: '¿Compró?', class: "align-middle"},            
            {key: 'observations', label: 'Observaciones', class: "align-middle"},
            {key: 'read', label: 'Estado', class: "align-middle text-center"},
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,             
        },
        crud: {
          form: {
            id: 0,
            date: '',
            observations: '',            
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,
            read: false,
            buy: false,
            exceed_previous_order: false,            
            reason: [],
            buy_competition: false,
            name_competition: '',
            competition_products: '',
            motives: [],
            financing_detail: '',
            other_motives: '',    
            quality: false,
            price: false,
            financing: false,
            customer_support: false,
            response_tickets: false,
            delivery_time: false,    
            verify_date_store: true,
          },  
          print: {
            reference: 'reports-visit',
            id: 0,
            key: 0,
          }          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        arr: {
          export: [],
          filter : {
            customers: [],
            staff: [],
            sellers: [],
            read: [
              {code:true, label: 'Leido'},
              {code:false, label: 'No Leidos'},                            
            ],            
          },
          options: {
            reason: [
              { text: 'Precio Elevado', value: 'precio_elevado' },
              { text: 'Dispone de Stock', value: 'dispone_stock' },
              { text: 'A la espera de pedido pendiente', value: 'espera_pedido_pendiente' },
              { text: 'No esta conforme con los diseños del producto', value: 'no_conforme_disenio_producto' },
              { text: 'No esta conforme con la calidad del producto', value: 'no_conforme_calidad_producto' },
              { text: 'No esta conforme con la atención al cliente', value: 'no_conforme_atencion_cliente'},
              { text: 'No esta conforme con la atención ante un reclamo', value: 'no_conforme_atencion_reclamos' },
              { text: 'No esta conforme con los plazos de entrega', value: 'no_conforme_plazo_entrega' },
              { text: 'No esta conforme con la financiación vigente', value: 'no_conforme_financiacion_vigente' },     
              { text: 'Estaba de vacaciones', value: 'estaba_vacaciones' },
            ],
            competition: [
              { text: '¿Por calidad?', value: 'por_calidad' },
              { text: '¿Por precio?', value: 'por_precio' },
              { text: '¿Por financiacion?', value: 'por_financiacion' },
              { text: '¿Por servicio de atención al cliente?', value: 'por_atencion_al_cliente' },
              { text: '¿Por respuesta ante reclamo?', value: 'por_respuesta_ante_reclamo' },
              { text: '¿Por plazo de entrega?', value: 'por_plazo_entrega' },
              { text: '¿Otros motivos?', value: 'por_otros_motivos' },        
            ],   
          }
        },
        filter: {
          date_start: '',
          date_end: '',
          customers: [],
          staff: [],
          sellers: [],
          read: null,
        },     
        customersID: 0,  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.editReport = Helper.hasAccessElement(this.access, 'edit_report')
      this.access.elements.deleteReport = Helper.hasAccessElement(this.access, 'delete_report')       
      /* Fin configuracion */
    },  
    mounted() {     
      this.customersID = this.$route.params.customersID
      if(this.customersID) {
        this.validCustomers()
      }

      if(this.isSeller) {
        this.$router.push({ name: 'ReportsVisitsStaffCrudReportSeller' })
      }

      this.filterLoad()           
      this.filterReport()
    },
    computed: {
      formartedItems () {
        if (!this.table.items) return []
        return this.table.items.map(item => {
          if(!item.read){
            item._rowVariant  = 'active'
          }
          return item
        })
      },
      isSeller(){
        if(Helper.getSeller()) {
          return true
        } else {
          return false
        }
      }              
    },    
    methods: {
      validCustomers() {        
        this.add()
        this.crud.form.customers_id = this.customersID
      },

      add() {
        this.crud.form.id = 0
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.customers_id = 0
        this.crud.form.customers = null
        this.crud.form.read = false
        this.crud.form.buy = false
        this.crud.form.exceed_previous_order = false
        this.crud.form.reason = []
        this.crud.form.buy_competition = false
        this.crud.form.name_competition = ''
        this.crud.form.competition_products = ''
        this.crud.form.motives = []
        this.crud.form.financing_detail = ''
        this.crud.form.other_motives = ''
        this.crud.form.quality = false
        this.crud.form.price = false
        this.crud.form.financing = false
        this.crud.form.customer_support = false
        this.crud.form.response_tickets = false
        this.crud.form.delivery_time = false
        this.crud.form.verify_date_store = true

        this.modal.form.title = "Nuevo Reporte"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.observations = item.observations
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.customers_id = item.customers_id
        this.crud.form.customers = item.customer
        this.crud.form.read = item.read
        this.crud.form.buy = item.buy
        this.crud.form.exceed_previous_order = item.exceed_previous_order
        this.crud.form.reason = item.reason
        this.crud.form.buy_competition = item.buy_competition
        this.crud.form.name_competition = item.name_competition
        this.crud.form.competition_products = item.competition_products
        this.crud.form.motives = item.motives
        this.crud.form.financing_detail = item.financing_detail
        this.crud.form.other_motives = item.other_motives
        this.crud.form.quality = item.quality
        this.crud.form.price = item.price
        this.crud.form.financing = item.financing
        this.crud.form.customer_support = item.customer_support
        this.crud.form.response_tickets = item.response_tickets
        this.crud.form.delivery_time = item.delivery_time
        this.crud.form.verify_date_store = true

        this.modal.form.title = "Editar Reporte"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.customers = item.customer

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') realizado al cliente ('+ this.crud.form.customers.name + ')?', {
          title: 'Borrar Reporte',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminar(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterReport()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la operación?', {
          title: 'Guardar Reporte',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'Aceptar',
          cancelVariant: 'outline-dark',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();

            this.prepareCrudForm()

            if (this.crud.form.id) {
              var result = serviceAPI.editar(this.crud.form);
            } else {
              var result = serviceAPI.agregar(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterReport()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })               
      },
      prepareCrudForm() {
        this.crud.form.quality = this.crud.form.motives.indexOf('por_calidad') > -1
        this.crud.form.price = this.crud.form.motives.indexOf('por_precio') > -1
        this.crud.form.financing = this.crud.form.motives.indexOf('por_financiacion') > -1
        this.crud.form.customer_support = this.crud.form.motives.indexOf('por_atencion_al_cliente') > -1
        this.crud.form.response_tickets = this.crud.form.motives.indexOf('por_respuesta_ante_reclamo') > -1
        this.crud.form.delivery_time = this.crud.form.motives.indexOf('por_plazo_entrega') > -1
        
        if(!this.crud.form.buy) {
          this.crud.form.exceed_previous_order = false
        }

        if(this.crud.form.buy && this.crud.form.exceed_previous_order) {
          this.crud.form.reason = []
        }

        if(!this.crud.form.buy_competition) {
          this.crud.form.name_competition = ''
          this.crud.form.competition_products = ''
          this.crud.form.motives = []
        }

        if(this.crud.form.motives.indexOf('por_financiacion') == -1) {
          this.crud.form.financing_detail = ''
        }

        if(this.crud.form.motives.indexOf('por_otros_motivos') == -1) {
          this.crud.form.other_motives = ''
        }        
      },
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id        
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0
        }
      }, 
      
      filterLoad() {
        this.filterLoadCustomers()
        this.filterLoadStaff()     
        this.filterLoadSellers()   
        this.filter.date_start = moment().subtract(7, 'days').format('YYYY-MM-DD') 
        this.filter.date_end = moment().format('YYYY-MM-DD') 
        
        this.restoreFilterStorage() 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.customers = []
          data.forEach(element => {            
            this.arr.filter.customers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadStaff() {
        var result = serviceAPI.obtenerStaff()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.staff = []
          data.forEach(element => {                            
            if(!element.seller) {             
              this.arr.filter.staff.push({ code: element.id, label: element.name })    
            }                    
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.sellers = []
          data.forEach(element => {                            
            this.arr.filter.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },      
      filterReport(){
        this.table.isBusy = true  
   
        var result = serviceAPI.filtrarReport(this.filter, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.prepareExport(data.data)

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }               

          this.table.isBusy = false
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });          
      }, 
      prepareExport(data) {
        data.forEach(element => {
          var staffID = 0            
          var staffName = ''
          var sellersID = 0
          var sellersName = ''
          var customersID = 0
          var customersCode = ''
          var customersName = ''

          if(element.staff_id) {
            staffID = element.staff.id
            staffName = element.staff.name
          } else {
            sellersID = element.seller.id
            sellersName = element.seller.name
          }

          if(element.customers_id) {
            customersID = element.customer.id
            customersCode = element.customer.code
            customersName = element.customer.name
          }

          this.arr.export.push({
            id: element.id,
            date: element.date,
            sellersID: sellersID,
            sellersName: sellersName,
            staffID: staffID,
            staffName: staffName,
            customersID: customersID,
            customersCode: customersCode,
            customersName: customersName,
            observations: element.observations,
            buy: element.buy,
            exceed_previous_order: element.exceed_previous_order,            
            reason: element.reason,
            buy_competition: element.buy_competition,
            name_competition: element.name_competition,
            competition_products: element.competition_products,
            motives: element.motives,
            financing_detail: element.financing_detail,
            other_motives: element.other_motives,
            quality: element.quality,
            price: element.price,
            financing: element.financing,
            customer_support: element.customer_support,
            response_tickets: element.response_tickets,
            delivery_time: element.delivery_time,
          })
        });
      },

      checkRead(item) {
        let loader = this.$loading.show();     
        
        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.observations = item.observations
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.customers_id = item.customers_id
        this.crud.form.customers = item.customer
        this.crud.form.read = true
        this.crud.form.buy = item.buy
        this.crud.form.exceed_previous_order = item.exceed_previous_order
        this.crud.form.reason = item.reason
        this.crud.form.buy_competition = item.buy_competition
        this.crud.form.name_competition = item.name_competition
        this.crud.form.competition_products = item.competition_products
        this.crud.form.motives = item.motives
        this.crud.form.financing_detail = item.financing_detail
        this.crud.form.other_motives = item.other_motives
        this.crud.form.quality = item.quality
        this.crud.form.price = item.price
        this.crud.form.financing = item.financing
        this.crud.form.customer_support = item.customer_support
        this.crud.form.response_tickets = item.response_tickets
        this.crud.form.delivery_time = item.delivery_time
        this.crud.form.verify_date_store = false

        var result = serviceAPI.editar(this.crud.form);
      
        result.then((response) => {          
          loader.hide()
          this.filterReport()
          this.$awn.success("Reporte marcado como Leido");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },
      checkNotRead(item) {
        let loader = this.$loading.show();     
        
        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.observations = item.observations
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.customers_id = item.customers_id
        this.crud.form.customers = item.customer
        this.crud.form.read = false
        this.crud.form.buy = item.buy
        this.crud.form.exceed_previous_order = item.exceed_previous_order
        this.crud.form.reason = item.reason
        this.crud.form.buy_competition = item.buy_competition
        this.crud.form.name_competition = item.name_competition
        this.crud.form.competition_products = item.competition_products
        this.crud.form.motives = item.motives
        this.crud.form.financing_detail = item.financing_detail
        this.crud.form.other_motives = item.other_motives
        this.crud.form.quality = item.quality
        this.crud.form.price = item.price
        this.crud.form.financing = item.financing
        this.crud.form.customer_support = item.customer_support
        this.crud.form.response_tickets = item.response_tickets
        this.crud.form.delivery_time = item.delivery_time        
        this.crud.form.verify_date_store = false

        var result = serviceAPI.editar(this.crud.form);
      
        result.then((response) => {          
          loader.hide()
          this.filterReport()
          this.$awn.success("Reporte marcado como No Leido");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_report_visit', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_report_visit')) {
          this.filter = JSON.parse(Storage.getValue('filter_report_visit'))
        }         
      },

      irDetail(item){
        this.saveFilterStorage()
        this.$router.push({ name: 'ReportsVisitsStaffDetailReport', params: {reportID: item.id} })
      },
      sendPrinterOrder(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
      }
    }    
  }
</script>
<style>
  .report-visits-crud-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }
  .report-visits-check-read-table {
    cursor: pointer;
  }   
</style>